import axios from "axios";
import router from "@/router/index";

axios.defaults.baseURL = "https://api.kody.zepco.work/";
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
    (config) => {
        const user = JSON.parse(localStorage.getItem("user"));
        const token = user?.token ?? "";

        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.code == "ERR_NETWORK") {
            return Promise.reject({
                response: {
                    data: {
                        message: 'Błąd sieci. Sprawdź połączenie z internetem.',
                    }
                }
            });
        }

        if (401 === error.response.status) {
            localStorage.clear();
            router.push({ name: "login" });
        }

        return Promise.reject(error);
    }
);
