const messages = {
  pl: {
    place: "0 miejsc | {count} miejsce | {count} miejsca | {count} miejsc",
  },
};

const pluralPLRule = (choice) => {
  if (choice === 0) {
    return 0;
  }

  if (choice === 1) {
    return 1; // singular normative
  }

  if (
    choice % 10 >= 2 &&
    choice % 10 <= 4 &&
    (choice % 100 < 10 || choice % 100 >= 20)
  ) {
    return 2; // plural normative
  }

  return 3; // plural genitive
};

export { messages, pluralPLRule };
