import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router/index";
import { createI18n } from "vue-i18n";
import { messages, pluralPLRule } from "@/i18n/messages";
import Toast from "vue-toastification";
import vSelect from "vue-select";
import moment from 'moment';
import "@/axios.js";
import "@/assets/app.css";
import VueSweetalert2 from "vue-sweetalert2";

moment.locale('pl');

const i18n = createI18n({
    locale: "pl",
    pluralizationRules: {
        pl: pluralPLRule,
    },
    messages,
});

let app = createApp(App);

app.config.globalProperties.$filters = {
    timeAgo(date) {
        return moment(date).calendar();
    }
};

app.use(router)
    .use(i18n)
    .use(Toast, {
        closeButton: false,
        hideProgressBar: true,
        position: "bottom-center",
        timeout: 2000,
    })
    .use(VueSweetalert2, {
        confirmButtonText: "Jasne!",
        confirmButtonColor: "#3b82f6",
        cancelButtonText: "Ups, to pomyłka!",
    })
    .component("v-select", vSelect)
    .mount("#app");

