import { createRouter, createWebHashHistory } from "vue-router";
import { useToast } from "vue-toastification";

const toast = useToast();

const routes = [
  {
    path: "/",
    redirect: () => {
      return { path: "codes" };
    },
  },
  {
    path: "/codes",
    name: "codes",
    component: () => import("@/pages/CodesPage"),
  },
  {
    path: "/cities",
    meta: { requiredManager: true },
    children: [
      {
        path: "new",
        name: "city.new",
        component: () => import("@/pages/city/NewCityPage"),
      },
      {
        path: "edit/:id",
        name: "city.edit",
        component: () => import("@/pages/city/EditCityPage"),
      },
      {
        path: ":city_id",
        children: [
          {
            path: "places",
            children: [
              {
                path: "new",
                name: "place.new",
                component: () => import("@/pages/place/AddEditForm"),
                props: { editForm: false },
              },
              {
                path: "edit/:id",
                name: "place.edit",
                component: () => import("@/pages/place/AddEditForm"),
                props: { editForm: true },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/users",
    meta: { requiredAdmin: true },

    children: [
      {
        path: "",
        name: "users",
        component: () => import("@/pages/user/UsersIndexPage"),
      },
      {
        path: ":id",
        name: "user.show",
        component: () => import("@/pages/user/ShowUserPage"),
      },
      {
        path: "new",
        name: "user.new",
        component: () => import("@/pages/user/NewUserPage"),
      },
      {
        path: "edit/:id",
        name: "user.edit",
        component: () => import("@/pages/user/EditUserPage"),
      },
    ],
  },
  {
    path: "/info",
    name: "info",
    component: () => import("@/pages/InfoPage"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/LoginPage"),
  },
  // {
  //   path: '*',
  //   component: () => import('@/pages/Error404.vue')
  // }
];

const router = createRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  history: createWebHashHistory(),
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};

  if (to.name !== "login" && user !== null && Object.keys(user).length < 1) {
    toast.error("Musisz być zalogowany, żeby korzystać z serwisu");
    next("login");
  } else {
    if (to.meta.requiredManager && user.role < 2) {
      toast.error("Nie masz uprawnień by wykonać tę operację!");
      next("codes");
    }

    if (to.meta.requiredAdmin && user.role < 3) {
      toast.error("Nie masz uprawnień by wykonać tę operację!");
      next("codes");
    }

    next();
  }
});

export default router;
